//PACKAGES
import { format, subDays } from "date-fns";
import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import z from "zod";
//DUMMY DATA
import DrugsUniquePost from "../dummyData/DrugsUniquePost";
import DrugsPosts from "../dummyData/DrugsPosts";
import DrugUsers from "../dummyData/DrugUsers";
import DrugUserProfile from "../dummyData/DrugUserProfile";
import DrugUserProfileMore from "../dummyData/DrugUserProfileMore";
import allPossibleMarketPlaces from "../dummyData/allPossibleMarketPlaces";
import allPossibleUserCountries from "../dummyData/allPossibleUserCountries";

//TYPES
import {
  DrugPostItemResponse,
  DrugPostsUniqueResponse,
  DrugUserListingResponse,
  DrugUserUniquePage,
  ResponseActiveUsersMore,
  ResponseDrugUsersTable,
} from "../types";
import {
  DrugPostItemResponseSchema,
  DrugUserListingResponseSchema,
  DrugUserUniquePageSchema,
  DrugsUniquePostSchema,
  ResponseActiveUserDataMoreSchema,
  ShippingDetailsSchema,
  TopVendorsSchema,
  TotalListingSchema,
  TotalVendorSchema,
  UseTableItemSchema,
} from "../schema";
import allPossibleCategories from "../dummyData/allPossibleCategories";
import allPossibleCountries from "../dummyData/allPossibleCountries";

const isDev = import.meta.env.MODE === "development";
const endpoint = "https://darkmon.saptanglabs.com/api/v2";
const endpointUser = "https://darkmon.saptanglabs.com/api";

export const fetchDrugPosts = async (
  currentPage: number,
  perPage: number,
  search: string,
  sortOrder: string,
  sortBy: string,
  shippingFrom: string[],
  shippingTo: string[],
  dateFrom: Date | null,
  dateTo: Date | null,
  categories: string[],
  marketPlaceNames: string[],
  exclude_marketplace?: string[],
) => {
  const formattedDateFrom = dateFrom !== null ? format(dateFrom, "yyyy-MM-dd") : "";
  const formattedDateTo = dateTo !== null ? format(dateTo, "yyyy-MM-dd") : "";

  try {
    const token = Cookies.get("token") ?? "";
    const params: {
      search?: string;
      page: number;
      per_page: number;
      sort_by: string;
      sort_order: string;
      date_from?: string;
      date_to?: string;
    } = {
      page: currentPage,
      per_page: perPage,
      sort_by: sortBy || "published_date",
      sort_order: sortOrder || "desc",
    };

    if (search.length > 0) {
      params.search = search;
    }

    if (formattedDateFrom.length > 0) {
      params.date_from = formattedDateFrom;
    }

    if (formattedDateTo.length > 0) {
      params.date_to = formattedDateTo;
    }

    const body: {
      shipping_from?: string[];
      shipping_to?: string[];
      drug_category?: string[];
      marketplace_name?: string[];
      exclude_marketplace?: string[];
    } = {};

    if (shippingFrom.length > 0) {
      body.shipping_from = shippingFrom;
    }

    if (shippingTo.length > 0) {
      body.shipping_to = shippingTo;
    }

    if (categories.length > 0) {
      body.drug_category = categories;
    }

    if (marketPlaceNames.length > 0) {
      body.marketplace_name = marketPlaceNames;
    }

    if (exclude_marketplace && exclude_marketplace.length > 0) {
      body.exclude_marketplace = exclude_marketplace;
    }

    if (isDev) return DrugPostItemResponseSchema.parse(DrugsPosts);

    const response: AxiosResponse<DrugPostItemResponse> = await axios.post(
      `${endpoint}/private/drug/postslist`,
      body,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return DrugPostItemResponseSchema.parse(response.data);
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};

export const fetchIndividualDrugPost = async (id: string) => {
  if (isDev) return z.array(DrugsUniquePostSchema).parse(DrugsUniquePost);
  try {
    const token = Cookies.get("token") ?? "";
    const params = {
      id,
    };

    const response: AxiosResponse<DrugPostsUniqueResponse[]> = await axios.post(
      `${endpoint}/private/drug/post`,
      {},
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (response.status === 200) {
      if (Array.isArray(response.data)) {
        return z.array(DrugsUniquePostSchema).parse(response.data);
      } else {
        return "Server error";
      }
    }
    if (response.data === undefined) {
      return "Server error";
    }

    return "Server error";
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};

export const fetchDrugUsers = async (
  currentPage: number,
  search: string,
  sortOrder: string,
  sortBy: string,
  perPage: number,
  dateFrom: Date | null,
  dateTo: Date | null,
  marketPlaceName: string[],
) => {
  if (isDev) return UseTableItemSchema.parse(DrugUsers);

  const formattedDateFrom = dateFrom !== null ? format(dateFrom, "yyyy-MM-dd") : "";
  const formattedDateTo = dateTo !== null ? format(dateTo, "yyyy-MM-dd") : "";
  try {
    const token = Cookies.get("token") ?? "";
    const params: {
      search?: string;
      page: number;
      per_page: number;
      sort_by: string;
      sort_order: string;
      date_from?: string;
      date_to?: string;
    } = {
      page: currentPage,
      per_page: perPage,
      sort_by: sortBy || "published_date",
      sort_order: sortOrder || "desc",
    };

    if (search.length > 0) {
      params.search = search;
    }
    if (formattedDateFrom.length > 0) {
      params.date_from = formattedDateFrom;
    }

    if (formattedDateTo.length > 0) {
      params.date_to = formattedDateTo;
    }

    if (formattedDateFrom && !formattedDateTo) {
      params.date_to = format(subDays(new Date(), 1), "yyyy-MM-dd");
    }

    const body: {
      marketplace_name?: string[];
    } = {};

    if (marketPlaceName.length > 0) {
      body.marketplace_name = marketPlaceName;
    }
    const response: AxiosResponse<ResponseDrugUsersTable> = await axios.post(
      `${endpoint}/private/drug/userlist`,
      body,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return UseTableItemSchema.parse(response.data);
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};

export const fetchDrugUserProfile = async (marketplace_name: string, vendor_name: string) => {
  if (isDev) return z.array(DrugUserUniquePageSchema).parse(DrugUserProfile);

  try {
    const token = Cookies.get("token") ?? "";
    const params = {
      marketplace_name,
      vendor_name,
    };

    const response: AxiosResponse<DrugUserUniquePage[]> = await axios.post(
      `${endpoint}/private/drug/userprofile`,
      {},
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (Array.isArray(response.data)) {
      return z.array(DrugUserUniquePageSchema).parse(response.data);
    }

    if (response.data === undefined) {
      return "Server error";
    }

    return "Server error";
  } catch (err) {
    console.log(err);
    return "Server error";
  }
};

export const fetchDrugUsersMore = async (marketplace_name: string, vendor_name: string) => {
  if (isDev) return z.array(ResponseActiveUserDataMoreSchema).parse(DrugUserProfileMore);

  try {
    const token = Cookies.get("token") ?? "";
    const params = {
      marketplace_name,
      vendor_name,
    };

    const response: AxiosResponse<ResponseActiveUsersMore[]> = await axios.get(
      `${endpoint}/private/drug/userprofile/more`,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (Array.isArray(response.data)) {
      const result = await z.array(ResponseActiveUserDataMoreSchema).safeParseAsync(response.data);

      if (result) {
        const data = z.array(ResponseActiveUserDataMoreSchema).parse(response.data);
        if (data.length === 0) {
          return undefined;
        }
        return data;
      } else {
        return "Server error";
      }
    }

    if (response.data === undefined) {
      return "Server error";
    }

    return "Server error";
  } catch (err) {
    console.log(err);
    return "Server error";
  }
};

export const fetchDrugUserProfileForums = async (
  marketplace_name: string,
  vendor_name: string,
  currentPage?: number,
  perPage?: number,
  search?: string,
  sortOrder?: string,
  sortBy?: string,
  shippingFrom?: string[],
  shippingTo?: string[],
  dateFrom?: Date | null,
  dateTo?: Date | null,
  categories?: string[],
) => {
  if (isDev) return DrugUserListingResponseSchema.parse(DrugsPosts);

  try {
    const token = Cookies.get("token") ?? "";
    const params: {
      page: number;
      per_page: number;
      sort_by: string;
      sort_order: string;
      search?: string;
      date_from?: string;
      date_to?: string;
    } = {
      page: currentPage ?? 1,
      per_page: perPage ?? 1,
      sort_by: sortBy || "published_date",
      sort_order: sortOrder || "desc",
    };
    const formattedDateFrom = dateFrom && dateFrom !== null ? format(dateFrom, "yyyy-MM-dd") : "";
    const formattedDateTo =
      dateTo && dateTo !== null ? format(subDays(dateTo ?? new Date(), 1), "yyyy-MM-dd") : "";

    if (search && search.length > 0) {
      params.search = search;
    }

    if (formattedDateFrom.length > 0) {
      params.date_from = formattedDateFrom;
    }

    if (formattedDateTo.length > 0) {
      params.date_to = formattedDateTo;
    }
    const body: {
      shipping_from?: string[];
      shipping_to?: string[];
      drug_category?: string[];
      marketplace_name: string[];
      vendor_name: string[];
    } = {
      marketplace_name: [marketplace_name],
      vendor_name: [vendor_name],
    };

    if (shippingFrom && shippingFrom.length > 0) {
      body.shipping_from = shippingFrom;
    }

    if (shippingTo && shippingTo.length > 0) {
      body.shipping_to = shippingTo;
    }

    if (categories && categories.length > 0) {
      body.drug_category = categories;
    }
    const response: AxiosResponse<DrugUserListingResponse> = await axios.post(
      `${endpoint}/private/drug/postslist`,
      body,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return DrugUserListingResponseSchema.parse(response.data);
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};

//
//  Analytics Page
//

export const fetchDrugAnalyticsDrugListings = async () => {
  if (isDev)
    return TotalListingSchema.parse([
      {
        listing_count: 12440,
      },
    ]);

  try {
    const token = Cookies.get("token") ?? "";

    const response: AxiosResponse = await axios.get(`${endpoint}/private/drug/home/totalistings`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (Array.isArray(response.data)) {
      return TotalListingSchema.parse(response.data);
    }

    if (response.data === undefined) {
      return "Server error";
    }

    return "Server error";
  } catch (err) {
    console.log(err);
    return "Server error";
  }
};

export const fetchDrugAnalyticsVendor = async () => {
  if (isDev)
    return TotalVendorSchema.parse([
      {
        vendor_count: 499,
      },
    ]);

  try {
    const token = Cookies.get("token") ?? "";

    const response: AxiosResponse = await axios.get(`${endpoint}/private/drug/home/totalvendors`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (Array.isArray(response.data)) {
      return TotalVendorSchema.parse(response.data);
    }

    if (response.data === undefined) {
      return "Server error";
    }

    return "Server error";
  } catch (err) {
    console.log(err);
    return "Server error";
  }
};

export const fetchDrugAnalyticsShippingDetails = async (direction: string) => {
  if (isDev)
    return ShippingDetailsSchema.parse([
      {
        _id: "United States",
        count: 4242,
      },
      {
        _id: "United Kingdom",
        count: 2270,
      },
      {
        _id: "Germany",
        count: 1687,
      },
      {
        _id: "Netherlands",
        count: 1296,
      },
      {
        _id: "Unknown Location",
        count: 1138,
      },
      {
        _id: "India",
        count: 482,
      },
      {
        _id: "France",
        count: 347,
      },
      {
        _id: "Australia",
        count: 222,
      },
      {
        _id: "Spain",
        count: 179,
      },
      {
        _id: "Poland",
        count: 118,
      },
      {
        _id: "Canada",
        count: 95,
      },
      {
        _id: "Switzerland",
        count: 91,
      },
      {
        _id: "China",
        count: 72,
      },
      {
        _id: "Czech Republic",
        count: 32,
      },
      {
        _id: "Belgium",
        count: 30,
      },
      {
        _id: "Austria",
        count: 25,
      },
      {
        _id: "Hong Kong",
        count: 10,
      },
      {
        _id: "Brazil",
        count: 10,
      },
      {
        _id: "South Africa",
        count: 10,
      },
      {
        _id: "Mexico",
        count: 6,
      },
      {
        _id: "Thailand",
        count: 6,
      },
      {
        _id: "Slovenia",
        count: 3,
      },
      {
        _id: "Netherlands Antilles",
        count: 2,
      },
      {
        _id: "World Wide",
        count: 2,
      },
      {
        _id: "Sweden",
        count: 2,
      },
      {
        _id: "South Korea",
        count: 1,
      },
      {
        _id: "Colombia",
        count: 1,
      },
      {
        _id: "Pakistan",
        count: 1,
      },
      {
        _id: "Turkey",
        count: 1,
      },
      {
        _id: "Slovakia",
        count: 1,
      },
      {
        _id: "Hungary",
        count: 1,
      },
      {
        _id: "Lithuania",
        count: 1,
      },
    ]).slice(0, 15);

  try {
    const params = {
      option: direction,
    };
    const token = Cookies.get("token") ?? "";

    const response: AxiosResponse = await axios.get(
      `${endpoint}/private/drug/home/shippingdetails`,
      {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (Array.isArray(response.data)) {
      return ShippingDetailsSchema.parse(response.data).slice(0, 10);
    }

    if (response.data === undefined) {
      return "Server error";
    }

    return "Server error";
  } catch (err) {
    console.log(err);
    return "Server error";
  }
};

export const fetchDrugAnalyticsTopVendors = async () => {
  if (isDev)
    return TopVendorsSchema.parse([
      {
        listing_count: 70,
        vendor_name: "NextGeneration",
        sales_count: 144267,
      },
      {
        listing_count: 5,
        vendor_name: "DreamWeaver",
        sales_count: 32438,
      },
      {
        listing_count: 7,
        vendor_name: "TorDrug",
        sales_count: 20065,
      },
      {
        listing_count: 11,
        vendor_name: "royalqueenweed",
        sales_count: 17391,
      },
      {
        listing_count: 64,
        vendor_name: "MicroDroper",
        sales_count: 16577,
      },
      {
        listing_count: 68,
        vendor_name: "SocialPharma",
        sales_count: 15706,
      },
      {
        listing_count: 99,
        vendor_name: "discover",
        sales_count: 15418,
      },
      {
        listing_count: 15,
        vendor_name: "YOURDEALER",
        sales_count: 14990,
      },
      {
        listing_count: 20,
        vendor_name: "Utopia",
        sales_count: 14986,
      },
      {
        listing_count: 42,
        vendor_name: "queenofcannabis",
        sales_count: 12370,
      },
    ]);

  try {
    const token = Cookies.get("token") ?? "";

    const response: AxiosResponse = await axios.get(`${endpoint}/private/drug/home/topvendors`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (Array.isArray(response.data)) {
      return TopVendorsSchema.parse(response.data);
    }

    if (response.data === undefined) {
      return "Server error";
    }

    return "Server error";
  } catch (err) {
    console.log(err);
    return "Server error";
  }
};

export const fetchAllNewMarketplace = async (): Promise<string[] | "Server error"> => {
  if (isDev) return allPossibleMarketPlaces;
  try {
    const token = Cookies.get("token") ?? "";

    const response: AxiosResponse<string[]> = await axios.get(
      `${endpoint}/private/drug/key/marketplace`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};

export const fetchAllCategories = async (): Promise<
  | {
      categories: Array<string | null>;
    }[]
  | "Server error"
> => {
  if (isDev) return allPossibleCategories;
  try {
    const token = Cookies.get("token") ?? "";

    const response: AxiosResponse<
      {
        categories: Array<string | null>;
      }[]
    > = await axios.get(`${endpoint}/private/drug/key/category`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};

export const fetchAllCountries = async (): Promise<
  | {
      Countries: Array<string | null>;
    }[]
  | "Server error"
> => {
  if (isDev) return allPossibleCountries;
  try {
    const token = Cookies.get("token") ?? "";

    const response: AxiosResponse<
      {
        Countries: Array<string | null>;
      }[]
    > = await axios.get(`${endpoint}/private/drug/key/country`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};

export const fetchAllUserCountries = async (): Promise<string[] | "Server error"> => {
  if (isDev) return allPossibleUserCountries;
  try {
    const token = Cookies.get("token") ?? "";

    const response: AxiosResponse<string[]> = await axios.get(
      `${endpointUser}/private/key/country`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};
export const fetchShippingFromToPossibleOptions = async (): Promise<
  | {
      Countries: Array<string | null>;
    }[]
  | "Server error"
> => {
  if (isDev) return allPossibleCountries;
  try {
    const token = Cookies.get("token") ?? "";

    const response: AxiosResponse<
      {
        Countries: Array<string | null>;
      }[]
    > = await axios.get(`${endpoint}/private/drug/key/country`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};

export const fetchNewShippingFromToPossibleOptions = async (): Promise<
  | {
      Countries: Array<string | null>;
    }[]
  | "Server error"
> => {
  if (isDev) return allPossibleCountries;
  try {
    const token = Cookies.get("token") ?? "";

    const response: AxiosResponse<
      {
        Countries: Array<string | null>;
      }[]
    > = await axios.get(`${endpoint}/private/drug/key/country`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};

export const fetchAllNewCategories = async (): Promise<
  | {
      categories: Array<string | null>;
    }[]
  | "Server error"
> => {
  if (isDev) return allPossibleCategories;
  try {
    const token = Cookies.get("token") ?? "";

    const response: AxiosResponse<
      {
        categories: Array<string | null>;
      }[]
    > = await axios.get(`${endpoint}/private/drug/key/category`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};
